import axios from "axios";

const API = "clientes-estadisticas";

const clientes = {
    listaTiposClientes(params){
        return axios(`${API}/lista-tipos-clientes`, { params });
    },
    listaTiposClientesTeam(params){
        return axios(`${API}/lista-tipos-clientes-team`, { params });
    },
    crecimiento(params){
        return axios(`${API}/crecimiento`, { params });
    },
    informe(params){
        return axios(`${API}/informe`, { params });
    },
    ver_cliente(params){
        return axios(`${API}/ver-cliente`, { params });
    },
    tabla_cliente(params){
        return axios(`${API}/tabla`, { params });
    },
    editar_cliente(params){
        return axios.post(`${API}/editar-cliente`, params );
    },
    actualizarGrupo(id, form){
        return axios.put(`${API}/${id}/actualizar-grupo`,form);
    },
    actualizarReferido(id, form){
        return axios.put(`${API}/${id}/actualizar-referido`,form);
    },
    buscarReferidos(params){
        return axios(`${API}/buscar-referido`, { params });
    },
    eliminarReferido(id){
        return axios.delete(`${API}/${id}/eliminar-referido`);
    },
    eliminarGrupo(id){
        return axios.delete(`${API}/${id}/eliminar-grupo`);
    },

    show: id => axios.get(`${API}/${id}/ver-cliente`),
    estadistica: (params) => axios.get(`${API}/estadistica`, { params }),
    pedidosRealizados: (params) => axios.get(`${API}/estadistica-pedidos-realizados`, { params }),
    productosComprados: (params) => axios.get(`${API}/estadistica-productos-comprados`, { params }),

    getClientesLeechero(idTienda){
        return axios.get(`${API}/${idTienda}/clientes-leechero`)
    },
    getLeecheros(params){
        return axios.get(`${API}/leecheros/listar`, {params})
    },
    postAgregarLeecheros(form){
        return axios.post(`${API}/leecheros/agregar`, form)
    },
    postCederClientesLeechero(form){
        return axios.post(`${API}/leecheros/ceder`, form)
    },
};

export default clientes;
