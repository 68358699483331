import axios from "axios";

const API = "metricas-teams";

const metricas = {
    ventas_mensuales(params){
        return axios(`${API}/ventas-mensuales`, { params });
    },
    ventas_diarias(params){
        return axios(`${API}/ventas-diarias`, { params });
    },
    calificaciones_mensuales(params){
        return axios(`${API}/calificaciones-mensuales`, { params });
    },
    comparativos(params){
        return axios(`${API}/comparativos`, { params });
    },
    info_tenderos(params){
        return axios.post(`${API}/info-tenderos`,params);
    },
    crecimiento(params){
        return axios(`${API}/crecimiento`, { params });
    },
    informe(params){
        return axios(`${API}/informe`, { params });
    },
    ver_cliente(params){
        return axios(`${API}/ver-cliente`, { params });
    },
};

export default metricas;
